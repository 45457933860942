<template>
  <div :class="$style['water-analysis-container']">
    <div v-if="data.length" :class="$style['water-analysis-content']">
      <p :class="$style['water-price']" v-if="showPrice">
        <span v-if="type === 1">水费单价: {{ price }}元/m³</span>
        <span v-else>电费单价: {{ price }}元/度</span>
      </p>
      <BaseWaterAndElectItems
        :configData="data"
        :class="$style['water-electric']"
      />
    </div>
    <EmptyContent v-else />
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import BaseWaterAndElectItems from '@/views/screen/project/people-management/components/base-worker-items';
@Component({
  components: {
    EmptyContent,
    BaseWaterAndElectItems,
  },
})
export default class WaterCostAnalysis extends Vue {
  @Prop({ type: Array, default: () => [] }) data;
  @Prop({ type: Boolean, default: true }) showPrice;
  @Prop({ type: Number, default: 1 }) type;
  @Prop({ type: Number, default: 0 }) price;
  created() {}
  mounted() {}
}
</script>
<style lang="less" module>
.water-analysis-container {
  // height: calc(100% - 0.2rem);
  height: 100%;
  position: relative;
  padding: 0.08rem;
  .water-analysis-content {
    height: 100%;

    .water-price {
      font-size: 0.14rem;
      font-family: 'Microsoft YaHei';
      font-weight: 400;
      color: #37d7db;
      line-height: 0.18rem;
      text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      margin: 0;
      padding: 0;
    }
    .water-electric {
      .base-worker-items_item {
        width: 200px;
      }
    }
  }
}
</style>
