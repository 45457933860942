<template>
  <div :class="$style['water-index']">
    <div :class="$style['water-top']">
      <LayoutCard :class="$style.card" title="水费分析">
        <LayoutSelect
          slot="action"
          :options="dateList"
          default-value="CURRENT_MONTH"
          @change="val => handleChangeSelect(val, 'waterCostDays')"
        />
        <LayoutSelect
          slot="action"
          :options="deviceListWater"
          :placeholder="$t('waterElectricity.allDevice')"
          @change="val => handleChangeSelect(val, 'waterCostAllDevice')"
        />
        <WaterCostAnalysis
          :data="waterCostAnalysisData"
          :price="waterPrice"
        ></WaterCostAnalysis>
      </LayoutCard>
      <LayoutCard :class="$style.card" title="用水/用电设备">
        <WaterCostAnalysis
          :data="waterCostEquipmentData"
          :showPrice="false"
        ></WaterCostAnalysis>
      </LayoutCard>
      <LayoutCard :class="$style.card" title="电费分析">
        <LayoutSelect
          slot="action"
          :options="dateList"
          default-value="CURRENT_MONTH"
          @change="val => handleChangeSelect(val, 'elecCostDays')"
        />
        <LayoutSelect
          slot="action"
          :options="deviceListElect"
          :placeholder="$t('waterElectricity.allDevice')"
          @change="val => handleChangeSelect(val, 'electCostAllDevice')"
        />
        <WaterCostAnalysis
          :data="electCostEquipmentData"
          :price="elecPrice"
          :type="2"
        ></WaterCostAnalysis>
      </LayoutCard>
    </div>
    <div :class="$style['water-bottom']">
      <div :class="$style.item1">
        <LayoutCard :class="$style.card" title="用水趋势分析">
          <LayoutSelect
            slot="action"
            :options="dateList1"
            default-value="SEVEN_DAY"
            @change="val => handleChangeSelect(val, 'waterTrendCostDays')"
          />
          <div class="h-full overflow-hidden" style="padding:.2rem .1rem">
            <LineChart
              chart-width="100%"
              chart-height="80%"
              isSmooth
              yName="单位：m3"
              symbol="round"
              :chart-data="waterTrendAnalysisData"
              :show-legend="true"
            />
          </div>
        </LayoutCard>
      </div>
      <div :class="$style.item2">
        <LayoutCard :class="$style.card" title="用电设备"
          ><ScrollTable
            :columns="elecTbaleColumns"
            :data="getElectRecordsData"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div :class="$style.item3">
        <LayoutCard :class="$style.card" title="用电趋势分析">
          <LayoutSelect
            slot="action"
            :options="dateList1"
            default-value="SEVEN_DAY"
            @change="val => handleChangeSelect(val, 'elecTrendCostDays')"
          />
          <div class="h-full overflow-hidden" style="padding:.2rem .1rem">
            <LineChart
              chart-width="100%"
              chart-height="80%"
              isSmooth
              yName="单位：度"
              symbol="round"
              :chart-data="elecTrendAnalysisData"
              :show-legend="true"
            />
          </div>
        </LayoutCard>
      </div>
      <div :class="$style.item4">
        <LayoutCard :class="$style.card" title="用水分析">
          <LayoutSelect
            slot="action"
            :options="dateList"
            default-value="CURRENT_MONTH"
            @change="val => handleChangeSelect(val, 'waterAnalsisDays')"
          />
          <ul :class="$style['key-tips']">
            <li>
              <span :class="$style['key-title']">总用水量</span>
              <span :class="$style['key-value']">{{
                scrollWaterTbaleObj.totalUsed
              }}</span>
            </li>
            <li>
              <span :class="$style['key-title']">阀值</span>
              <span :class="$style['key-value']">{{
                scrollWaterTbaleObj.threshold
              }}</span>
            </li>
            <li>
              <span :class="$style['key-title']">超出用水</span>
              <span :class="$style['key-value']">{{
                scrollWaterTbaleObj.excessUsage
              }}</span>
            </li>
          </ul>
          <ScrollTable
            :columns="scrollWaterTbaleColumns"
            :data="scrollWaterTbaleData"
            @load="loadScroll"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div :class="$style.item5">
        <LayoutCard :class="$style.card" title="用水设备">
          <ScrollTable
            :columns="waterTbaleColumns"
            :data="getWaterRecordsData"
          ></ScrollTable>
        </LayoutCard>
      </div>
      <div :class="$style.item6">
        <LayoutCard :class="$style.card" title="用电分析">
          <LayoutSelect
            slot="action"
            :options="dateList"
            default-value="CURRENT_MONTH"
            @change="val => handleChangeSelect(val, 'elecAnalsisDays')"
          />
          <ul :class="$style['key-tips']">
            <li>
              <span :class="$style['key-title']">总用电量</span>
              <span :class="$style['key-value']">{{
                scrollElectTbaleObj.totalUsed
              }}</span>
            </li>
            <li>
              <span :class="$style['key-title']">阀值</span>
              <span :class="$style['key-value']">{{
                scrollElectTbaleObj.threshold
              }}</span>
            </li>
            <li>
              <span :class="$style['key-title']">超出用电</span>
              <span :class="$style['key-value']">{{
                scrollElectTbaleObj.excessUsage
              }}</span>
            </li>
          </ul>
          <ScrollTable
            :columns="scrollElectTbaleColumns"
            :data="scrollElectTbaleData"
            @load="loadScroll"
          ></ScrollTable>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import WaterCostAnalysis from './components/waterCostAnalysis.vue';
import { LayoutCard, LayoutSelect } from '../../layout';
import LineChart from '@/views/screen/e-charts/line-chart';
import ScrollTable from '@/views/screen/components/scroll-table.vue';
import {
  getWaterAndElectCount,
  getWaterAnalysisData,
  getWaterCostAnalysisData,
  getElecAnalysisData,
  getWaterTrendAnalysis,
  getElecTrendAnalysis,
  getElecAnalysisTableData,
  getElectRecords,
  getWaterRecords,
} from '@/services/screen/jiangsu/water-elect';
import { crossStorageModule } from '@/enum/store.js';
@Component({
  components: {
    WaterCostAnalysis,
    LayoutSelect,
    LayoutCard,
    LineChart,
    ScrollTable,
  },
})
export default class WaterElectricityIndex extends Vue {
  @crossStorageModule.State('tenant') tenant;
  created() {
    this.init();
  }
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  init() {
    this.getWaterAndElectCountData();
    this.getWaterCostAnalysisDataObj();
    this.getElecAnalysisDataObj();
    this.getWaterTrendAnalysisData();
    this.getElecTrendAnalysisData();
    this.getWaterAnalysisTableData();
    this.getElectAnalysisTableData();
    this.getElectRecordsData();
    this.getWaterDeviceRecordsData();
    this.getElecDeviceRecordsData();
  }
  //水电设备数
  get waterCostEquipmentData() {
    return [
      {
        title: '用水设备数',
        value: this.waterAndElecCountData.waterNumber,
      },
      {
        title: '用电设备数',
        value: this.waterAndElecCountData.electricityNumber,
      },
    ];
  }
  waterAndElecCountParams = {
    projectId: this.projectId,
  };
  waterAndElecCountData = {};
  async getWaterAndElectCountData() {
    const res = await getWaterAndElectCount(this.waterAndElecCountParams);
    this.waterAndElecCountData = res;
  }

  //水费分析
  get waterCostAnalysisData() {
    return [
      {
        title: '累计水费',
        value: `${this.waterAnalysis.totalCost || 0}元`,
      },
      {
        title: '水费',
        value: `${this.waterAnalysis.cost || 0}元`,
      },
    ];
  }
  waterAnalysisCountParams = {
    projectId: this.projectId,
    days: 'CURRENT_MONTH',
    deviceId: '',
  };
  waterAnalysis = {};
  waterPrice = 0;
  async getWaterCostAnalysisDataObj() {
    const res = await getWaterCostAnalysisData(this.waterAnalysisCountParams);
    //window.console.log(res, 'getWaterCostAnalysisDataObj');
    this.waterAnalysis = res;
    this.waterPrice = res.unitPrice;
  }

  //电费花费分析
  get electCostEquipmentData() {
    return [
      {
        title: '累计电费',
        value: `${this.elecAnalysisData.totalCost || 0}元`,
      },
      {
        title: '电费',
        value: `${this.elecAnalysisData.cost || 0}元`,
      },
    ];
  }
  elecPrice = 0;
  elecAnalysisCountParams = {
    projectId: this.projectId,
    days: 'CURRENT_MONTH',
    deviceId: '',
  };
  elecAnalysisData = {};
  async getElecAnalysisDataObj() {
    const res = await getElecAnalysisData(this.elecAnalysisCountParams);
    //window.console.log(res, 'getElecAnalysisDataObj');
    this.elecAnalysisData = res;
    this.elecPrice = res.unitPrice;
  }
  dateList = [
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: '本月',
      value: 'CURRENT_MONTH',
    },
    {
      label: '最近半年',
      value: 'SIX_MONTH',
    },
    {
      label: '最近一年',
      value: 'ONE_YEAR',
    },
  ];
  dateList1 = [
    // {
    //   label: this.$t('screen.currentDay'),
    //   value: 'CURRENT_DAY',
    // },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  //用水趋势
  waterTrendAnalysisParmas = {
    days: 'SEVEN_DAY',
    projectId: this.projectId,
  };
  waterTrendAnalysisData = [];
  async getWaterTrendAnalysisData() {
    const res = await getWaterTrendAnalysis(this.waterTrendAnalysisParmas);
    //window.console.log(res, 'getWaterTrendAnalysisData');
    const data = [];
    res.dataList.forEach(item => {
      data.push({
        name: item.tag || '用水量(m³)',
        data: item.values,
      });
    });
    this.waterTrendAnalysisData = [
      {
        time: res.times,
        data,
      },
    ];
  }

  //用电趋势
  elecTrendAnalysisParmas = {
    days: 'SEVEN_DAY',
    projectId: this.projectId,
  };
  elecTrendAnalysisData = [];
  async getElecTrendAnalysisData() {
    const res = await getElecTrendAnalysis(this.elecTrendAnalysisParmas);
    //window.console.log(res, 'getElecTrendAnalysisData');
    const data = [];
    res.dataList.forEach(item => {
      data.push({
        name: item.tag || '用电量(度)',
        data: item.values,
      });
    });
    this.elecTrendAnalysisData = [
      {
        time: res.times,
        data,
      },
    ];
  }

  handleChangeSelect(val, type) {
    // window.console.log(val, type, 'handleChangeSelect');
    switch (type) {
      case 'waterCostDays':
        this.waterAnalysisCountParams.days = val;
        this.getWaterCostAnalysisDataObj();
        break;
      case 'waterCostAllDevice':
        this.waterAnalysisCountParams.deviceId = val;
        this.getWaterCostAnalysisDataObj();
        break;
      case 'elecCostDays':
        this.elecAnalysisCountParams.days = val;
        this.getElecAnalysisDataObj();
        break;
      case 'electCostAllDevice':
        this.elecAnalysisCountParams.deviceId = val;
        this.getElecAnalysisDataObj();
        break;
      case 'waterTrendCostDays':
        this.waterTrendAnalysisParmas.days = val;
        this.getWaterTrendAnalysisData();
        break;
      case 'elecTrendCostDays':
        this.elecTrendAnalysisParmas.days = val;
        this.getElecTrendAnalysisData();
        break;
      case 'waterAnalsisDays':
        this.scrollWaterTbaleParams.days = val;
        this.getWaterAnalysisTableData();
        break;
      case 'elecAnalsisDays':
        this.scrollElectTbaleParams.days = val;
        this.getElectAnalysisTableData();
        break;

      default:
        break;
    }
  }
  scrollTbaleColumns = [
    {
      title: '设备名称',
      key: 'index',
      align: 'center',
    },
    {
      title: '设备码',
      key: 'index',
    },
    {
      title: '最近更新时间',
      key: 'index',
    },
  ];
  //用水分析
  scrollWaterTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      align: 'center',
    },
    {
      title: '用水量(m³)',
      key: 'used',
    },
    {
      title: '用水占比(%)',
      key: 'percentage',
    },
  ];

  scrollWaterTbaleData = [];
  scrollWaterTbaleObj = {};
  scrollWaterTbaleParams = {
    projectId: this.projectId,
    days: 'CURRENT_MONTH',
    deviceId: '',
  };
  async getWaterAnalysisTableData() {
    const res = await getWaterAnalysisData(this.scrollWaterTbaleParams);
    //window.console.log(res, 'getWaterAnalysisTableData');
    for (const key in res) {
      if (typeof res[key] === 'number') {
        this.scrollWaterTbaleObj[key] = res[key];
      }
    }
    this.scrollWaterTbaleData = res.deviceUseds;
  }

  //用电分析
  scrollElectTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      align: 'center',
    },
    {
      title: '用电量(度）',
      key: 'used',
    },
    {
      title: '用电占比(%)',
      key: 'percentage',
    },
  ];

  scrollElectTbaleData = [];
  scrollElectTbaleObj = {};
  scrollElectTbaleParams = {
    projectId: this.projectId,
    days: 'CURRENT_MONTH',
    deviceId: '',
  };
  async getElectAnalysisTableData() {
    const res = await getElecAnalysisTableData(this.scrollElectTbaleParams);
    //window.console.log(res, 'getElectAnalysisTableData');
    for (const key in res) {
      if (typeof res[key] === 'number') {
        this.scrollElectTbaleObj[key] = res[key];
      }
    }
    this.scrollElectTbaleData = res.deviceUseds;
  }
  //用电设备
  deviceListElect = [];
  async getElecDeviceRecordsData() {
    const { records } = await getElectRecords({
      size: 100,
      current: 1,
      projectId: this.projectId,
    });
    this.deviceListElect = records.map(ele => ({
      label: ele.deviceName,
      value: ele.pkId,
    }));
    this.deviceListElect = [this.allDevices, ...this.deviceListElect];
  }
  //电表设备分页
  elecTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      align: 'center',
    },
    {
      title: '设备码',
      key: 'deviceCode',
    },
    {
      title: '最近更新时间',
      key: 'updatedTime',
      format: text =>
        text ? this.dayjs(text).format('YYYY-MM-DD hh:ss:mm') : '-',
    },
  ];

  async getElectRecordsData(data) {
    const { records } = await getElectRecords({
      size: data?.pageSize || 10,
      current: data?.current || 1,
      projectId: this.projectId,
    });
    return records;
  }

  //用水设备
  deviceListWater = [];
  allDevices = {
    label: '全部设备',
    value: '',
  };
  async getWaterDeviceRecordsData() {
    const { records } = await getWaterRecords({
      size: 100,
      current: 1,
      projectId: this.projectId,
    });
    this.deviceListWater = records.map(ele => ({
      label: ele.deviceName,
      value: ele.pkId,
    }));
    this.deviceListWater = [this.allDevices, ...this.deviceListWater];
  }
  //用水设备分页
  waterTbaleColumns = [
    {
      title: '设备名称',
      key: 'deviceName',
      align: 'center',
    },
    {
      title: '设备码',
      key: 'deviceCode',
    },
    {
      title: '最近更新时间',
      key: 'updatedTime',
      format: text =>
        text ? this.dayjs(text).format('YYYY-MM-DD hh:ss:mm') : '-',
    },
  ];
  async getWaterRecordsData(data) {
    const { records } = await getWaterRecords({
      size: data?.pageSize || 10,
      current: data?.current || 1,
      projectId: this.projectId,
    });
    return records;
  }
  loadScroll() {}
}
</script>

<style lang="less" module>
.water-index {
  height: 100%;
  .water-top {
    height: 2rem;
    display: flex;
    flex-shrink: 0;
    .card {
      flex: 1;
      // margin-left: 0.1rem;
      background: linear-gradient(
        270deg,
        rgba(76, 175, 255, 0) 0%,
        rgba(76, 175, 255, 0.08) 52%,
        rgba(76, 175, 255, 0) 100%
      );
      &:nth-of-type(2) {
        margin: 0 0.1rem;
      }
    }
  }
  .water-bottom {
    display: grid;
    margin-top: 0.1rem;
    grid-template-rows: repeat(2, 49%);
    grid-template-columns: repeat(3, 33%);
    grid-gap: 0.1rem;
    height: calc(100% - 2.1rem);
    // background: red;
    .item2 {
      height: 100%;
      width: 100%;
    }
    .key-tips {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.302);
      font-family: 'YouSheBiaoTiHei';
      letter-spacing: 2px;
      font-weight: 400;
      line-height: 28px;
      li {
        margin-top: 0.23rem;
        .key-title {
          font-size: 0.18rem;
          color: var(--screen-font-2);
        }
        .key-value {
          font-size: 0.24rem;
          margin-left: 0.07rem;
        }
      }
    }
  }
}
</style>
