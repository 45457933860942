import { request, serviceName, oldServiceName } from './base-config';

// 水电表数量
export function getWaterAndElectCount(data) {
  return request(
    `${serviceName}/screen/waterAndElectricity/countDeviceNumber`,
    {
      method: 'GET',
      body: data,
    },
  );
}

//用水费用分析
export function getWaterCostAnalysisData(data) {
  return request(`${serviceName}/screen/waterAndElectricity/waterCost`, {
    method: 'GET',
    body: data,
  });
}

//用水分析
export function getWaterAnalysisData(data) {
  return request(`${serviceName}/screen/waterAndElectricity/waterUsedAnalyse`, {
    method: 'GET',
    body: data,
  });
}

//用电分析
export function getElecAnalysisTableData(data) {
  return request(
    `${serviceName}/screen/waterAndElectricity/electricityUsedAnalyse`,
    {
      method: 'GET',
      body: data,
    },
  );
}

//用电费用分析
export function getElecAnalysisData(data) {
  return request(`${serviceName}/screen/waterAndElectricity/electricityCost`, {
    method: 'GET',
    body: data,
  });
}

//用水趋势分析
export function getWaterTrendAnalysis(data) {
  return request(`${serviceName}/screen/waterAndElectricity/waterTrend`, {
    method: 'GET',
    body: data,
  });
}

//用电趋势分析
export function getElecTrendAnalysis(data) {
  return request(`${serviceName}/screen/waterAndElectricity/electricityTrend`, {
    method: 'GET',
    body: data,
  });
}

//获取水表数据列表  smart-site/device/wm/record/page

export function getWaterRecords(data) {
  return request(`${oldServiceName}/device/wm/search/list`, {
    method: 'POST',
    body: data,
  });
}

//获取电表数据列表
export function getElectRecords(data) {
  return request(`${oldServiceName}/device/em/search/list`, {
    method: 'POST',
    body: data,
  });
}
